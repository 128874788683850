import { useRouter } from 'next/router';

import { getAuthToken } from '@/recoil/atoms/common/auth';

export interface LoginViewModel {
  redirectUrl: string;
  redirect: () => void;
  isLoggedIn: boolean;
}

const useLoginViewModel = (): LoginViewModel => {
  const router = useRouter();
  const redirectUrl = (router.query['redirect_url'] as string) || '/';
  const isLoggedIn = !!getAuthToken();
  const redirect = () => {
    router.push(redirectUrl);
  };

  return {
    isLoggedIn: isLoggedIn,
    redirect: redirect,
    redirectUrl: redirectUrl,
  };
};

export default useLoginViewModel;
