// PageDescription: 로그인 페이지
import React, { useEffect } from 'react';

import EmptyLayout from '@/components/layouts/EmptyLayout';
import LoginModal from '@/components/molecules/LoginModal';
import Overlay from '@/components/molecules/Overlay';
import useLoginViewModel from '@/hooks/login/useLoginViewModel';

const LoginPage = () => {
  const { redirectUrl, isLoggedIn, redirect } = useLoginViewModel();

  useEffect(() => {
    if (isLoggedIn) {
      redirect();
    }
  }, [isLoggedIn]);

  return (
    <Overlay>
      <LoginModal
        isWritePage={false}
        onClick={redirect}
        redirectUrl={redirectUrl}
      />
    </Overlay>
  );
};

export default LoginPage;

LoginPage.Layout = EmptyLayout;
